// 套餐和a+b
<template>
  <el-row v-if="searchProducts.length>0">
    <el-col :span="24">
      <div class="zone">
        <div class="HeaderTit">
           <h3>{{titmsg}}</h3>
           <span class="subhead2">
               优选组合
           </span>
           <div class="moreInfo"  @click="jumpProductType">更多</div>
        </div>
        <div class="goodsList">
          <SetMealItem  class="goods" v-for="(item,index) in searchProducts" :key="index" :info="item"></SetMealItem>
      </div>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="less" scoped>
@import "~style/index.less";
.zone {
  overflow: hidden;
  .goodsList {
    height: auto;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #fff;
    width: 100%;
    .item {
      margin-top:0px;
      margin-right: 0px !important;
      margin-left: 14px;
      padding-right:8px;
    }
   .item:nth-child(5n + 0) {
      margin-right: 0px;
    }
  }
  
  .HeaderTit{
      margin-top:30px;
      padding: 14px 20px;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    h3 {
        float: left;
        margin-left: 10px;
        margin-right: 12px;
        color: #333;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .subhead1 {
         color: #F56C28;
          font-size:13px;
          margin-bottom: -6px;
    }
    .subhead2 {
         color: #CF03F9;
          font-size:13px;
          margin-bottom: -6px;
    }
    .moreInfo {
        position: absolute;
        right:20px;
         cursor: pointer;
            &:hover{
                color: #f43c38;
            }
         .fonts();
         color: #666;
    }
  }
  
  // .shopInfo {
  //   float: left;
  //   width: calc(100% - 228px);
  //   .item {
  //     margin-right: 0px !important;
  //     margin-left: 15px;
  //   }
  //   .item:nth-child(4n + 0) {
  //     margin-right: 0px;
  //   }
  // }
}
</style>

<script>
// comboItem
const SetMealItem=()=>import('components/index/SetMealItem.vue');
import { SetMealList  } from "api/activity.js";
// import Qs from "qs";
export default {
  name: "combo",
  data() {
    return {
      titmsg: "超值套餐",
      searchProducts: [], //初始商品数据
    };
  },
  props: {
    productType: {
      type: String,
    },
  },
  created() {
    SetMealList().then(res=>{
        //console.log(res.data.data)
          const records = res.data.data.records;
          this.searchProducts = records.slice(0,5);
          this.total = Number(res.data.data.total);
        
            // 关闭loading
          this.$loading().close();
      })
  },
  methods: {
      jumpProductType(){
            //    alert("超值套餐")
                this.$router.push({name:"SetMealList"})
        },
      
  },
  components: {
    SetMealItem
  },
};
</script>
